html {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}


/* common properties start */

.carousel-transition {
    transition: all .7s ease-in;
}

.text-main {
    color: #a5c735;
}

.text-other {
    color: black;
}

.bg-main {
    background-color: #a5c735;
}

.bg-other {
    background-color: black;
}

.border-main {
    border: 1px solid #a5c735;
}

.hv-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.item-center {
    display: flex;
    align-items: center;
}

.text-justify {
    text-align: justify;
}

.opacity-1 {
    opacity: 1;
}

.opacity-0 {
    opacity: 0;
}

.visible-1 {
    visibility: visible;
}

.visible-0 {
    visibility: hidden;
}

.transparency-7 {
    opacity: 0.7;
}

.line-height-wide {
    line-height: 1.75;
}

.line-height-medium {
    line-height: 1.5;
}

.carousel-indicators {
    visibility: hidden;
}


/* common properties end */


/* home properties here */

.on-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #161615c9;
}

.green-button {
    background-color: #a5c735;
    padding: 10px 25px;
    transition: all .3s ease-in-out;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: capitalize;
    border-radius: 20px;
    text-transform: uppercase;
    text-decoration: none;
}

.green-button:hover {
    background-color: black;
    color: white;
    text-decoration: underline;
}


/* home properties here */


/* drawer properties start */

.drawer {
    height: 0vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    /* visibility: hidden;
    opacity: 0; */
    opacity: 0.5;
    transition: all .5s ease-in-out;
    background-color: black;
}

.drawer-open {
    height: 45vh;
    width: 100%;
    display: block;
    position: fixed;
    /* opacity: 1;
    visibility: 1; */
    top: 0;
    left: 0;
    display: block;
    transition: all .5s ease-in-out;
    background-color: white !important;
    z-index: 9999;
}

.close-icon {
    width: 40px;
    height: 40px;
}

.mobile-link {
    color: black;
    font-weight: 600;
}

.mobile-link:hover {
    color: #a5c735;
    font-weight: 600;
}

.mobile-link-active {
    color: #a5c735;
    font-weight: 600;
}

.mobile-link-active:hover {
    color: #a5c735;
    font-weight: 600;
}


/* drawer properties end */


/* navbar properties start */

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link {
    color: #121212;
    font-weight: 500;
    transition: all .2s ease-in-out;
}

.link:hover {
    color: #a5c735;
    border-bottom: 2px solid #a5c735;
    padding: 0 15px 10px;
    font-weight: 600;
    font-size: 1.1rem;
}

.link-active {
    color: #a5c735;
    font-weight: 500;
    border-bottom: 2px solid #a5c735;
    padding: 0 15px 10px
}

.link-active:hover {
    color: #a5c735;
    font-weight: 500;
}


/* navbar properties end */


/* footer properties start */

.footer-small-font {
    font-size: .7rem;
    color: white;
}

.footer-large-font {
    font-size: 1rem;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
}

.footer-link {
    text-decoration: none;
    color: white;
    font-weight: 300;
    display: inline-block;
    margin-bottom: 0.4rem;
    font-size: 1rem;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
}

.footer-link:hover {
    color: #a5c735;
    padding: 0.5rem 0;
    font-size: 1.1rem;
}

.footer-icon {
    width: 50%;
    background-color: transparent;
    border: 0;
}

.outlead-link {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    transition: all .3s ease-in-out;
}

.outlead-link:hover {
    color: black;
    border-bottom: 1px solid #121212;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
    padding: 0 10px
}


/* footer properties end */
